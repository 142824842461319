.table td.data-col {
  padding: 0;

  &,
  & > div,
  & > div > .inner,
  & > div > .inner > textarea {
    width: 100%;
  }
  & > div > .inner > textarea {
    white-space: nowrap;
  }

  & > div > .inner {
    padding: .75rem;

    & > pre {
      margin: 0;
      overflow-x: auto;
    }
  }
}
