.btn {
  position: relative;
  
  .on-show {
    position: absolute;
  }
  .on-collapsed,
  .on-show {
    transition-property: opacity;
    transition-duration: .3s;
  }

  .on-collapsed {
    opacity: 0;
  }
  .on-show {
    opacity: 1;
  }

  &.collapsed {
    .on-collapsed {
      opacity: 1;
    }
    .on-show {
      opacity: 0;
    }
  }
}
