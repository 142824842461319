// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';

@import 'styles';
@import 'buttons';
@import 'list';
@import 'table';
@import 'pagination';
@import 'inline-edit';
